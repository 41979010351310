import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalServicesService } from '../services/global-services.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { FileUploadService } from '../services/file-upload.service';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  sourceImg = 'https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&w=600';
  dashboardActive: boolean = false;
  jobsActive: boolean = true;
  usersActive: boolean = false;
  ENV = environment.HOST.NODE_ENV;
  sideNavOptions: any = [];
  activePage: string = '';
  routeListener: any;
  companyLogo: string = '';

  constructor(private _router: Router, private globalservice: GlobalServicesService, private location: Location, private breadcrumbService: NgDynamicBreadcrumbService, private fileUploadService: FileUploadService) {
    let allPrivilege: any = {
      "userType": "admin",
      "userPrivileges": {
          "addNewEvents": true,
          "viewDashBoardIcon": true,
          "viewManageCandidateIcon": true,
          "editManageDrives": true,
          "viewManageContent": true,
          "editManageEventsDetailsJobRole": true,
          "viewManageEventsStages": [
              "Test",
              "Screening",
              "Interview",
              "OnBoarding",
              "Others",
              "Offer",
              ""
          ],
          "editManageEventsCandidate": false,
          "viewManageEventsDetails": true,
          "viewManageEventsList": true,
          "viewManageEventsIcon": true,
          "viewGeneralSettings": true,
          "editAccessManagement": true,
          "viewManageContentIcon": true,
          "editFeedback": true,
          "viewManageEventsInterview": true,
          "editManageRoles": true,
          "viewDashBoard": true,
          "viewManageDrives": true,
          "editManageEventsWorkflow": true,
          "viewGeneralSettingsIcon": true,
          "viewManageRoles": true,
          "candidateMoveStage": true,
          "editManageEventsInterview": true,
          "editManageUsers": true,
          "viewManageRolesIcon": true,
          "editGeneralSettings": true,
          "viewManageUsersIcon": true,
          "editEmailTemplate": true,
          "viewEmailTemplateIcon": true,
          "limitedViewManageDrives": false,
          "viewManageDrivesIcon": true,
          "downlaodCandidateDetails": true,
          "limitedViewManageEvents": false,
          "viewManageUsers": true,
          "viewEmailTemplate": true,
          "editManageCandidate": true,
          "editDashBoard": true,
          "viewManageCandidate": true,
          "editStagesOptions": true,
          "limitedViewManageUsers": false,
          "editManageEventsDetails": true,
          "editManageContent": true,
          "viewManageEventsWorkflow": true,
          "viewManageEventsCandidate": true
      }
  };
    
    let privilege = allPrivilege.userPrivileges;
    this.globalservice.privilegeDetails = privilege;
    console.log("Privilege Roles", allPrivilege)
    this.globalservice.privilegeRoles = allPrivilege.userType;
    this.sideNavOptions = [
      // { name: 'Dashboard', icon: 'dashboard', isActive: false, url: '', view: privilege?.viewDashBoardIcon ? true : false },
      { name: 'Manage Events', icon: 'events', isActive: true, url: 'events', view: privilege?.viewManageEventsIcon ? true : false },
      { name: 'Manage Candidate', icon: 'talent-pool', isActive: false, url: 'talentpool-management', view: privilege?.viewManageCandidateIcon ? true : false },
      { name: 'Manage Users', icon: 'users', isActive: false, url: 'users', view: privilege?.viewManageUsersIcon ? true : false },
      { name: 'Manage Job Roles', icon: 'roles', isActive: false, url: 'roles', view: privilege?.viewManageRolesIcon ? true : false },
      // { name: 'Manage Drives', icon: 'jobs', isActive: false, url: 'jobs', view: privilege?.viewManageDrivesIcon ? true : false },
      { name: 'Email Template', icon: 'email-template', isActive: false, url: 'email-template', view: privilege?.viewEmailTemplateIcon ? true : false },
      { name: 'Form Settings', icon: 'form-settings', isActive: false, url: 'form-settings', view: privilege?.viewGeneralSettingsIcon ? true : false },
      { name: 'General Settings', icon: 'settings', isActive: false, url: 'general-settings', view: privilege?.viewGeneralSettingsIcon ? true : false },
      { name: 'Manage Content', icon: 'sso-redirect', isActive: false, url: 'sso', view: privilege?.viewManageContentIcon ? true : false }]
  }

  ngOnInit() {
    this._router.events.subscribe(path => {
      if (path instanceof NavigationEnd) {
        let route = path.url;
        this.activePage = route.split('/')[1];
        let inMainPage = route.split('/').length <= 2
        this.sideNavOptions.forEach((option: any) => {
          option.isActive = false;
          if (option.url == this.activePage) {
            option.isActive = true;
            if(inMainPage)this.updateBreadcrumb(option.name);
          }
        });
      }
    });
    this.companyLogo = localStorage.getItem('company-details')
      ? JSON.parse(localStorage.getItem('company-details') || '').company_metadata.cover_image
      : '';
    if (this.companyLogo.length) this.sourceImg = this.companyLogo;
  }

  updateBreadcrumb(title: string): void {
    const breadcrumbs = [{ label: title, url: '' }];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  iconClick = (option: any) => {
    this.sideNavOptions.forEach((item: any) => {
      if (option.name !== 'Manage Content') {
        if (item.url === option.url) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      }
    });
    if (option.url === 'sso') {
      this.openSSO();
    }
    else if (option.url !== 'sso') {
      this._router.navigateByUrl(option.url);
    }
  }

  openSSO = () => {
    let payload = {
      source: "neo-hire",
    };
    this.globalservice.ssoLoginFastApi(payload).subscribe((resp: any) => {
      let url = resp.body.url;
      if (resp.body.url) {
        let target_host;
        if (this.ENV === 'dev') {
          target_host = 'exam.ly';
        } else if (this.ENV === 'acc') {
          target_host = 'examly.net';
        } else if ( this.ENV === 'prod' ){
          target_host = 'examly.io';
        }
        url = url.replace('examly.io', target_host);
        window.open(url, '_blank');
      }
    })
  }
}
