import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { TalentPoolService } from '../services/talent-pool.service';
import { EventServicesService } from '../services/event-services.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnChanges {
  currentPage: string = '';
  @Input() searchItem: any
  userDetails: any;
  userName: string = '-';
  activeTab: string ='All';
  mouseHover: boolean = false;
  showSearchBar: boolean = false;
  isLoading: boolean = false;
  searchText: string = '';
  order_by: string = 'desc';
  sort_by: string = 'created_at';
  pageSize: number = 10;
  pageNumber: number = 1;
  constructor(private _router: Router, private loginService: LoginService, private globalService: GlobalServicesService, 
    private talentPoolService: TalentPoolService,private eventServices: EventServicesService) {
    this.userDetails = localStorage.getItem('userDetailsFastApi')
      ? JSON.parse(localStorage.getItem('userDetailsFastApi') || '').user_profile : '';
    if (this.userDetails.first_name) {
      this.userName = this.userDetails.first_name + ' ' + this.userDetails.last_name;
    }
  }

  ngOnChanges(): void {
    if(this.searchItem.length)
    {
      this.showSearchBar = true;
    }
    else
    {
      this.showSearchBar = false;
    }
    this.searchFunction()
  }


  signOut = () => {
    this.removeKeys();
    this.loginService.validToken.next({
      status: 403,
      statusMessage: 'loggedOut',
    })
    // this.loginService.logout().subscribe((response) => {
    //   if (response.status === 200) {
    //     this.removeKeys();
    //     this.loginService.validToken.next({
    //       status: response.status,
    //       statusMessage: 'loggedOut',
    //       // message : {
    //       //   severity: 'success',
    //       //   summary: 'Success',
    //       //   detail: 'Logged out successfully',
    //       // }
    //     })
    //   }
    //   else {
    //     this.loginService.validToken.next({
    //       status: response.status,
    //       message: {
    //         severity: 'error',
    //         summary: 'Error',
    //         detail: 'Something went wrong!',
    //       }
    //     })
    //   }
    // })
  }
  removeKeys() {
    for (var attr in localStorage) {
      // if (attr != 'schoolData' && attr != 'schoolDetails')
        localStorage.removeItem(attr);
    }
  }

  searchList(event: any) {
    let value = event.target.value;
    if (value) {
      this.globalService.searchTerm = value;
    }
    else {
      this.globalService.searchTerm = '';
    }
    this.isLoading = true;
    let searchTerm = this.globalService.searchTerm;
    if (this.searchItem === 'Events') {
      let searchedEvent = searchTerm.trim().split(/\s+/);
      searchTerm = '';
      if (searchedEvent && searchedEvent.length === 1) {
        searchTerm = searchedEvent[0];
      } else if (searchedEvent.length > 0) {
        searchTerm = searchedEvent.join(' ');
      }
      this.eventServices.getEventsFastApi(this.globalService.pageNumber ? this.globalService.pageNumber : this.pageNumber, this.globalService.pageSize ? this.globalService.pageSize : this.pageSize, this.order_by, this.sort_by, searchTerm || undefined).subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this.isLoading = false;
          this.globalService.searchList.next(response.body);
          }
        },
        error: () => {
        }
      });
    } else if (this.searchItem === 'Candidate') {
      let searchedEmail = searchTerm.trim().split(/\s+/);
      searchTerm = '';
      if (searchedEmail && searchedEmail.length === 1) {
        searchTerm = searchedEmail[0];
      } else if (searchedEmail.length > 0) {
        searchTerm = searchedEmail.join(' ');
      }
      this.talentPoolService
        .getAllCandidatesFastApi(this.globalService.pageNumber ? this.globalService.pageNumber : this.pageNumber, this.globalService.pageSize ? this.globalService.pageSize : this.pageSize, this.order_by, this.sort_by, searchTerm || undefined)
        .subscribe({
          next: (response: any) => {
            if (response.status == 200) {
              this.isLoading = false;
              this.globalService.searchCandidateList.next(response.body);
            }
          },
          error: () => {
          }
        });
    }
  }

  searchFunction(){
    this.searchText = '';
    this.globalService.searchTerm = '';
  }

  searchTermUpdate()
  {
    let value = this.searchText;
    if(value.length === 0)
    {
      this.globalService.searchTerm = '';
    }
  }

  // showAll(){

  // }

  // showEvents(){
    
  // }

  // errorFileDownload(){
    
  // }
}

