import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { JobServicesService } from 'src/app/services/job-services.service';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { nanoid } from 'nanoid'
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { HttpResponse } from '@angular/common/http';

declare let tinymce: any;
export interface TemplateList {
  category: string;
  categoryId: string;
  id: string;
  isEditable: boolean;
  templateTitle: string;
  subjectTitle: string;
  templateContent: any;
  collapse: boolean;
  showIcons: boolean;
}

export enum Placeholders {
  User = ' ${User} ',
  UserId = ' ${UserId} ',
  EmailId = ' ${EmailId} ',
  UserType = ' ${UserType} ',
  Address = ' ${Address} ',
  SchoolName = ' ${SchoolName} ',
  ContactNumber = ' ${ContactNumber} ',
  Email_Signature = ' ${Email_Signature} ',
  Emails = ' ${Emails} ',
  Test_Link = ' ${Test_Link} ',
  Interview_Link = ' ${Interview_Link} ',
  Stage_Name = ' ${Stage_Name} ',
  Job_Name = ' ${Job_Name} ',
  Stage_Type = ' ${Stage_Type} ',
  Documents_Link = ' ${Documents_Link} ',
  SupportLink = ' ${SupportLink} ',
  Link = ' ${Link} ',
  Test_Name = ' ${Test_Name} ',
  Contest_Name = ' ${Contest_Name} ',
  Date = ' ${Date} ',
  Role_Name = ' ${Role_Name} ',
  offerConfirmationLink = ' ${offerConfirmationLink} ',
  cohortJobRole = ' ${cohortJobRole} ',
  cohortSpoc = ' ${cohortSpoc} ',
  cohortLocation = ' ${cohortLocation} ',
  cohortId = ' ${cohortId} ',
  cohortDate = ' ${cohortDate} ',
  OfferCohortDeadline = ' ${OfferCohortDeadline} ',
  DocumentCohortJobRole = ' ${DocumentCohortJobRole} ',
  DocumentCohortPOC = ' ${DocumentCohortPOC} ',
  DocumentCohortLocation = ' ${DocumentCohortLocation} ',
  DocumentCohortDeadline = ' ${DocumentCohortDeadline} ',
}

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class EmailTemplateComponent implements OnInit {
  categories: Array<any> = [];
  templateList: Array<TemplateList> = [];
  _templateList: Array<TemplateList> = [];
  addTemplateSidebar: boolean = false;
  newCategoryName: string = '';
  isLoading: boolean = true;
  selectedCategoryId: string = '';
  categoryRename: string = '';
  showPlaceholder: boolean = false;
  placeholders: Array<any> = [];
  focusedElement: any;
  cursorPositionId: string = '';
  cursor: Array<number> = [];

  _subjectTitle: string = '';
  _templateContent: string = '';
  _selectedCategory: any;
  _filteredCategory: any;
  _templateTitle: string = '';
  templateToEdit: string = '';
  saveBtnLoading: boolean = false;
  isAllActive: boolean = true;
  clicked: boolean = false;
  privilege: any = {};
  schoolId: string = '';
  templateSearchTerm: string = '';
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;

  addTemplateForm: FormGroup = new FormGroup({
    category: new FormControl(''),
    templateTitle: new FormControl('', [Validators.required]),
    subjectTitle: new FormControl('', [Validators.required]),
    templateContent: new FormControl(),
  });

  constructor(
    private jobServices: JobServicesService,
    private emailServices: EmailServicesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService,
    private breadcrumbService: NgDynamicBreadcrumbService
  ) {  }

  ngOnInit(): void {
    this.getCategories();
    this.emailTemplateFormData();
    this.privilege = this.globalService.privilegeDetails;
    this.schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    const breadcrumbs = [
      { label: 'Email Template', url: '' }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }
  emailTemplateFormData = () => {
    this.emailServices.getEmailTemplateFormDataFastApi().subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.placeholders = response.body.place_holders.map((placeholder: any) => ({
            value: ` \${${placeholder}} ` ,
            tag: placeholder,
          }));
        }
      },
      error: (_err) => { }
    });
  }
  getCategories = (renameId?:any,isDeleted?: boolean) => {
    this.isLoading = true;
    this.emailServices.getEmailCategoriesFastApi().subscribe({
      next: (response) => {
        if (response.status === 200) {
          let categories = response.body.items ? response.body.items : [];
          this.categories = categories.map((category: any) => ({
            name: category.name,
            id: category.id,
            isActive: renameId === category.id,
            isRenameCategory: false,
          }));
        }
        this.isLoading = false;
      },
      error: (_err) => {
        this.isLoading = false;
      }
    });
    if(isDeleted){
      this.isAllActive = true;
    }
    this.getEmailTemplates();
  };

  getEmailTemplates = () => {
    this.isLoading = true;
    this.emailServices.getTemplatesFastApi(this.isAllActive ? '' : this.selectedCategoryId).subscribe({
      next: (response) => {
        if (response.status === 200) {
          let templateList = response.body.email_templates ? response.body.email_templates : [];
          this.templateList = templateList.map((item: any) => ({
            category: item.category,
            categoryId: item.categoryId,
            id: item.id,
            isEditable: item.is_editable,
            templateTitle: item.name,
            subjectTitle: item.subjectTitle,
            templateContent: item.templateContent,
            collapse: true,
            showIcons: false,
          }));
          this._templateList = this.templateList;
        }
        this.isLoading = false;
      },
      error: (_err) => {
        this.isLoading = false;
      }
    });
  };
  openCategory = (name: string) => {
    if (name === 'All') {
      this.isAllActive = true;
      this.categories.forEach((val: any) => (val.isActive = false));
      this.selectedCategoryId = '';
      this._selectedCategory = '';
    } else {
      this.categories.forEach((val: any) => {
        if (val.name === name) {
          val.isActive = true;
          this.selectedCategoryId = val.id;
          this._selectedCategory = val;
        } else {
          val.isActive = false;
          this.isAllActive = false;
        }
      });
    }
    this.getEmailTemplates();
  };

  addCategory = (ip: any) => {
    if (this.newCategoryName) {
      const categoryExists = this.categories.some(category => category.name.toLowerCase() === this.newCategoryName.toLowerCase());
      if (categoryExists) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Category already exists!'
        });
        return;
      }
      this.isLoading = true;
      let payload = { name: this.newCategoryName };
      this.emailServices.createEmailCategoryFastApi(payload).subscribe({
        next: (response) => {
          if (response.status === 201) {
            this.emailServices.getEmailCategoriesFastApi().subscribe({
              next: (res) => {
                let categories = res.body.items ? res.body.items : [];
                this.categories = categories.map((category: any) => ({
                  name: category.name,
                  id: category.id,
                  isActive: false,
                  isRenameCategory: false,
                }));
                ip.deactivate();
                this.categories[this.categories.length - 1].isActive = true;
                this.newCategoryName = '';
                this.isLoading = false;
                this.isAllActive = false;
                this.selectedCategoryId = this.categories[this.categories.length - 1].id;
                this.getEmailTemplates();
              },
              error: (err) => {
                console.error('Error fetching categories:', err);
                this.isLoading = false;
              }
            });
          }
        },
        error: (_err) => {
          this.isLoading = false;
        }
      });
    }
  };
  openPanel(template: TemplateList){
    this.getSpecificTemplateData(template);
  }

  async getSpecificTemplateData(template: TemplateList): Promise<void> {
    if (template.id) {
      return new Promise((resolve, reject) => {
        this.emailServices.getSpecificTemplateFastApi(template.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              template.subjectTitle = response.body.subject;
              template.templateContent = response.body.body;
              this.selectedCategoryId = response.body.email_category_id;
              console.log(response.body);
              resolve();
            }
          },
          error: (err) => {
            reject(new Error('Error fetching specific template: ' + err));
          }
        });
      });
    }
  }

  searchTemplate() {
    this._templateList = this.templateList.filter(val => val.templateTitle.toLowerCase().includes(this.templateSearchTerm.toLowerCase()));
  }

  templateImageUpload(data: string): Promise<any> {
    return new Promise((resolved, rejected) => {
      const elem: any = document.createElement('div');
      elem.innerHTML = data;
      const img_array = elem.getElementsByTagName('img');
      const promiseArray = [];
      for (const match of img_array) {
        if (
          match.src.indexOf('amazonaws.com') === -1 &&
          match.src.indexOf('base64') !== -1
        ) {
          promiseArray.push(
            new Promise((res, rej) => {
              const pay = {
                Bucket_name: this.bucketName,
                file_name:
                  'email-template/' +
                  'email-attachments/' + 
                  this.schoolId +
                  '/' +
                  nanoid(10),
                type: match.src.split(';base64')[0].split(':')[1],
                ACL: 'public-read'
              };
              this.fileUploadService.getSignedUrl(pay).subscribe((url: any) => {
                if (url) {
                  const json = url;
                  this.fileUploadService
                    .uploadUsingSignedUrl(
                      json.data,
                      this.dataURItoBlob(match.src, pay.type)
                    )
                    .subscribe((r: any) => {
                      if (r && r.status === 200) {
                        let url =
                          'https://' +
                          pay.Bucket_name +
                          '.s3.amazonaws.com/' +
                          pay.file_name;
                        match.src = url;
                        res(url);
                      }
                    });
                }
              });
            })
          );
        }
      }
      Promise.all(promiseArray).then((s3Url: any) => {
        resolved(elem.innerHTML);
      });
    });
  }

  dataURItoBlob(dataURI: any, type: any) {
    dataURI = dataURI.split('" ')[0];
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: type });
  }

  saveTemplate = async () => {
    this.clicked = true;
    if (this.addTemplateForm.valid) {
      this.saveBtnLoading = true;
      let newTemplate = this.addTemplateForm.value;
      let editorText = tinymce.get(0).getContent()
        ? tinymce.get(0).getContent()
        : null;
      this.addTemplateForm.controls['templateContent'].setValue(editorText);

      let payload = {
        email_category_id: newTemplate.category?.id,
        name: newTemplate.templateTitle,
        subject: newTemplate.subjectTitle,
        body: tinymce.get(0).getContent()
          ? tinymce.get(0).getContent()
          : null
      };
      this.clicked = false;
      let updatedTemplate = await this.templateImageUpload(
        payload.body
      );
      updatedTemplate = updatedTemplate.replace(/\/\$\{/g, "${");
      payload.body = updatedTemplate || payload.body;
      if (this.templateToEdit) {
        this.emailServices.updateTemplateFastApi(payload, this.templateToEdit).subscribe({
          next: (response) => {
            this.handleTemplateResponse(response, 'Template Edited Successfully', 'Unable to Edit the template', 200);
          },
          error: (_err) => {
            this.saveBtnLoading = false;
          }
        });
      } else {
        this.emailServices.createTemplateFastApi(payload).subscribe({
          next: (response) => {
            this.handleTemplateResponse(response, 'Template Created Successfully', 'Unable to create the template', 201);
          },
          error: (_err) => {
            this.saveBtnLoading = false;
          }
        });
      }
    }
  };
  handleTemplateResponse = (response: HttpResponse<any>, successMessage: string, errorMessage: string, successStatusCode: number) => {
    if (response.status === successStatusCode) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: successMessage,
      });
      this.addTemplateForm.reset();
      this.getEmailTemplates();
      this.templateToEdit = '';
      this.addTemplateSidebar = false;
    } 
    this.saveBtnLoading = false;
  };

  async editTemplate(template: TemplateList) {
    await this.getSpecificTemplateData(template);
    this._filteredCategory = this.categories.filter(
      (item: any) => item.id === this.selectedCategoryId
    )[0];
    this._selectedCategory = this._filteredCategory;
    this._templateTitle = template.templateTitle;
    this._subjectTitle = template.subjectTitle;
    this._templateContent = template.templateContent;
    this.templateToEdit = template.id;
    this.openAddTemplate(template);
    tinymce.activeEditor.setContent(template.templateContent);
}

  deleteTemplate(template: TemplateList, event: any) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
        this.emailServices.deleteTemplateFastApi(template.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.getEmailTemplates();
              this.messageService.add({
                severity: 'info',
                summary: 'Deleted',
                detail: 'Template deleted',
              });
            }
          },
          error: (err) => {
            console.error('Error deleting specific template:', err);
          }
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
        });
      },
    });
  }

  getElement = (id: string) => {
    this.cursorPositionId = id;
    this.focusedElement = document.getElementById(id);
    if (this.cursorPositionId === 'title') {
      let [start, end] = [
        this.focusedElement.selectionStart,
        this.focusedElement.selectionEnd,
      ];
      this.cursor = [start, end];
    }
  };

  removeFocus() {
    this.focusedElement = null;
    this.cursorPositionId = '';
  }

  insertPlaceholder = (text: string) => {
    if (this.cursorPositionId === 'title') {
      let currentValue = this.focusedElement?.value;
      let patchedValue =
        currentValue.substring(0, this.cursor[0]) +
        text +
        currentValue.substring(this.cursor[1]);
      this._subjectTitle = patchedValue;
    }

    tinymce.activeEditor.insertContent(text);
  };

  Clear = () => {
    this.addTemplateForm.reset();
    this.globalService.removeScript();
    this.selectedCategoryId = '';
    this.loadTinyMce();
    if (tinymce.activeEditor) {
      tinymce.activeEditor.setContent('');
    }
  };

  onSave() { }

  async loadTinyMce(template?: any) {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/0eh36k1drqmz94mh92qtt7utdjc0pmvctldubb7sho99iiva/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    tinymce.remove();
    tinymce
      .init({
        selector: 'textarea',
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        height: 500,
        menu: '',
        plugins:
          'print preview paste searchreplace autolink ' +
          'directionality code visualblocks visualchars fullscreen link media ' +
          'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
          'wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
          'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
          'insertfile quickimage media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'wrap',
        contextmenu: 'link image imagetools table',
        relative_urls: false,
      })
      .then((editor: any) => {
        tinymce.activeEditor.setContent(template.templateContent);
      });
  }

  openAddTemplate(template?: any) {
    this.addTemplateSidebar = true;
    this._filteredCategory = this.categories.filter(
      (item: any) => item.id === this.selectedCategoryId
    )[0];
    this._selectedCategory = this._filteredCategory;
    this.globalService.removeScript();
    this.loadTinyMce(template);
  }
  deleteCategory = (category: any,event:any) => {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
        this.emailServices.deleteEmailCategoryFastApi(category.id).subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.getCategories(undefined,true);
              this.messageService.add({
                severity: 'info',
                summary: 'Deleted',
                detail: 'Category deleted',
              });
            }
          },
          error: (err) => {
            this.isLoading = false;
            console.error('Error deleting category:', err);
          }
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
        });
      },
    });
  };
  renameCategory = (category: any) => {
    if (this.categoryRename) {
      const categoryExists = this.categories.some(category => category.name.toLowerCase() === this.categoryRename.toLowerCase());
      if (categoryExists) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Category already exists!'
        });
        return;
      }
      this.isLoading = true;
      let payload : any = { name: this.categoryRename };
      this.emailServices.updateEmailCategoryFastApi( payload,category.id).subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.getCategories(category.id);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Category renamed successfully',
            });
          }
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Error renaming category:', err);
        }
      });
    }
  }
}
