import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class GlobalServicesService {
  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  fast_api_url = environment.HOST.FAST_API_URL;
  domain_url = environment.HOST.DOMAIN_URL;

  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;
  privilegeDetails: any = {};
  privilegeRoles: string = '';
  pageNumber: number = 0;
  pageSize: number = 0;
  searchTerm: string = '';
  talentTerm: string = '';
  status: string = 'All';
  companyUUID: string = '';
  // public dateFormat = new Subject<any>();
  // public timeFormat = new Subject<any>();
  public searchList = new Subject<any>();
  public searchCandidateList = new Subject<any>();
  public schoolData = new Subject<any>();

  public commonGrowl = new BehaviorSubject<{ severity?: string; summary?: string; detail?: string }>({});  bucketName: string = '';
  assetBucketName: string = '';

  constructor(private http: HttpClient) { 
    let nodeEnv = environment.HOST.NODE_ENV;
    if(nodeEnv === 'dev' || nodeEnv === 'acc')
    {
      this.bucketName = 'dev-neo-hire';
      this.assetBucketName = 'dev-neo-hire-asset'
    }
    else if(nodeEnv === 'prod')
    {
      this.bucketName = 'prod-neo-hire';
      this.assetBucketName = 'prod-neo-hire-asset'
    }
    
  }
  public handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Something went wrong; please try again later.';
  
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      console.error('A client-side error occurred:', error.error.message);
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      this.commonGrowl.next({});
      switch (error.status) {
        case 400:
          if(!this.commonGrowl.getValue().detail) {
          this.commonGrowl.next({
            severity: 'warn',
            summary: 'Warning',
            detail: error.error.detail || 'Bad Request. Please check your input and try again.'
          });
        }
          errorMessage = 'Bad Request. Please check your input and try again.';
          break;
        case 401:
          this.commonGrowl.next({
            severity: 'error',
            summary: 'Error',
            detail: 'Unauthorized. Please check your credentials.'
          });
          errorMessage = 'Unauthorized. Please check your credentials.';
          break;
        case 403:
          this.commonGrowl.next({
            severity: 'error',
            summary: 'Error',
            detail: 'Session expired.'
          });
          errorMessage = 'Forbidden. You do not have permission to perform this action.';
          for (var attr in localStorage) {
              localStorage.removeItem(attr);
          }
          break;
        case 404:
          this.commonGrowl.next({
            severity: 'warn',
            summary: 'Failed',
            detail: error.error.detail || 'Resource not found.'
          });
          errorMessage = 'Resource not found.';
          break;
        case 422:
            this.commonGrowl.next({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.detail.map((err: any) => err.msg).join(', ')
            });
          errorMessage = error.error.detail.map((err: any) => err.msg).join(', ');
          break;
        case 500:
          this.commonGrowl.next({
            severity: 'error',
            summary: 'Error',
            detail: 'Internal Server Error. Please try again later.'
          });
          errorMessage = 'Internal Server Error. Please try again later.';
          break;
        default:
          this.commonGrowl.next({
            severity: 'error',
            summary: 'Error',
            detail: `Unexpected error: ${error.message}`
          });
          errorMessage = `Unexpected error: ${error.message}`;
          break;
      }
    }
  
    return throwError(() => new Error(errorMessage));
  }
  

  options() {
    let href = 'admin.' + window.location.href.split('.')[1] + '.exam.ly';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      origin: href,
    });

    return { headers };
  }

  options_fast_api_with_company_uuid() {
    const tokenString: string | null = localStorage.getItem('token');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }
  mongoOptions() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      origin: window.location.href,
      Authorization: localStorage.getItem('token') ?? '',
      schoolid: schoolId,
    });
    return { headers };
  }
  options_fast_api() {
    const tokenString: string | null = localStorage.getItem('tokenFastApi');
    const token: any = tokenString ? JSON.parse(tokenString) : null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token.access_token}` : '',
      'company-uuid': localStorage.getItem('companyUUID') || ''
    });
    return { headers };
  }
  //fast apis
  getUserProfileFastApi(): Observable<HttpResponse<any>> { 
    return this.http.get<HttpResponse<any>>(this.fast_api_url + '/users/profile', {
      observe: 'response',
      ...this.options_fast_api()
    }).pipe(
      catchError(this.handleError.bind(this))
    ); 
  }

  getJobRolesLocationFastApi(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${this.fast_api_url}/job-roles/location`, {
      observe: 'response',
      ...this.options_fast_api()
    }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  //get school Data
  getSchoolData(): Observable<any> {
    return this.http.get(this.host_api_url + '/school/login', this.options());
  }

  getSchoolDetails(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.mongoOptions());
  }

  getCurentPageUrl() {
    return window.location.pathname;
  }

  loadScript(
    element: string,
    url: string,
    type: string,
    id: string,
    child?: any[],
    defer?: any,
    onload?: any,
    onerror?: any
  ): Promise<any> {
    return new Promise(async (resolved, rejected) => {
      if (document.getElementById(id)) {
        resolved(true);
      } else {
        const node: any = document.createElement(element);
        if (element === 'link') {
          node.href = url;
          node.rel = 'stylesheet';
        } else if (element === 'script') {
          node.src = url;
        }

        if (defer) {
          node.defer = defer;
        }

        if (onload) {
          node.onload = resolved(onload);
        }

        if (onerror) {
          node.onerror = resolved(onerror);
        }
        node.type = type;
        node.id = id;
        document.getElementsByTagName('head')[0].appendChild(node);
        node.onload = () => {
          if (child && child.length) {
            const promiseArray: any[] = [];
            child.forEach((script: any) => {
              promiseArray.push(
                this.loadScript(
                  script.element,
                  script.url,
                  script.type,
                  script.rel,
                  script.id,
                  script.child
                )
              );
            });
            Promise.all(promiseArray).then(() => {
              resolved(true);
            });
          } else {
            resolved(true);
          }
        };
        node.onerror = () => {
          rejected(false);
        };
      }
    });
  }

  removeScript() {
    let script = document.getElementById("examlyck")
    if (script) {
      script.remove();
    }
  }

  ssoLoginFastApi(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this.fast_api_url + '/manage-contest/hire/sso_login',payload, {
      observe: 'response',
      ...this.options_fast_api()
    }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getUserPrivileges(roleType: string): Observable<any> {
    return this.http.get(this.api_url + '/userPrivileges/getPrivileges/' + roleType, this.mongoOptions());
  }

  getResultAnalysis(payload: any, c_id: any, t_id: any, email: any): Observable<any> {
    return this.http.post(this.api_url + `/users/getResultAnalysis/${c_id}/${t_id}?email=${email}`, payload, this.mongoOptions());
  }

  getScheduleTest(email: any, jobId: any, stageId: any): Observable<any> {
    return this.http.get(this.api_url + `/test/${email}/${jobId}/${stageId}`, this.mongoOptions());
  }

  decryptPrivileges() {
    let data: any = localStorage.getItem("userPrivilege");
    if (!data) {
      return false;
    }
    let _key = CryptoJS.enc.Utf8.parse(this.encryptKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.encryptKey);
    let decrypted: any = CryptoJS.AES.decrypt(
      data, _key, {
      keySize: 256,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }

  getSearchEvents(searchTerm: string): Observable<any> {
    let userDetails: any = localStorage.getItem('userDetails');
    userDetails = JSON.parse(userDetails);
    return this.http.get(this.api_url + `/event/search/events?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&status=${this.status.toLocaleLowerCase()}&eventTitle=${searchTerm}&userId=${userDetails.id}`, this.mongoOptions());
  }

  getSearchCandidates(searchTerm: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/talentPool/allCandidates/search?pageNumber=' + this.pageNumber + '&pageSize=' + this.pageSize + '&search=' + searchTerm + '&fieldName=email', payload, this.mongoOptions());
  }

  getOpenIde(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/column/openIDE', payload, this.mongoOptions());
  }

  assessmentFilter(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/test/filter', payload, this.mongoOptions());
  }

  eventCandidateSearch(eventId: any, pageSize: any, pageNumber: any,payload: any) : Observable<any> {
    return this.http.post(this.api_url + '/users/search/' + eventId + '/candidate/?pageSize=' + pageSize + '&pageNumber=' + pageNumber, payload, this.options());
  }
}